import axios from "axios"
import { ElMessage } from 'element-plus'
import router from './router/index'
// const baseURL = 'http://127.0.0.1:18082';
const baseURL = 'https://seo.rayimedia.com/';
// const baseURL = 'https://mcndemo.xingzong.tech/';
// 创建axios实例
const https = axios.create({
    baseURL: baseURL, // 本地地址
    // baseURL: 'https://mcndemo.xingzong.tech/', // api的base_url
    timeout: 15000 // 请求超时时间
});

// 请求拦截器
https.interceptors.request.use(
    config => {
        let mathNumber = Math.floor(Math.random() * 899999 + 100000)
        config.headers['authorization'] = mathNumber + localStorage.getItem('token')
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        // 可以在这里添加请求头等信息
        return config;
    },
    error => {
        // 请求错误处理
        console.log(error);
        return Promise.reject(error);
    }
);

// 响应拦截器
https.interceptors.response.use(
    response => {
        // 可以在这里对响应数据进行处理
        if (response.data.code == 10002) {
            ElMessage.error('登录信息过期,请重新登录!')
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('currentProgram')
            router.push({
                path: '/'
            })
        }
        if (response.data.code == 1) {
            ElMessage.error(response.data.msg)
        }
        return response.data;
    },
    error => {
        // 响应错误处理
        console.log('err' + error); // for debug
        return Promise.reject(error);
    }
);

// const request = axios.post('http://www.xm001.com/index/login/index', {
//     username: 'admin',        // 参数 firstName
//     password: '12345678'    // 参数 lastName
// })

// let requestAll = {
//     request,
//     // getRing,
//     // getUserId
// }
export {https,baseURL};

