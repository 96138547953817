<template>
  <div class="distribute-page">
    <div style="width: 97.5vw;margin: auto;margin-top: 84px;">
      <div class="head">
        <div v-show="activeName == 'first'" class="flex_row_between" style="width: 100%;">
          <div class="buttonArr">
            <el-button type="primary" @click="showAdd()">新增账号</el-button>
            <el-button color="#6A5AE9" @click="DownloadFile">下载导入模板</el-button>
            <el-button type="danger" @click="handleDelete3()">批量删除</el-button>
          </div>
          <div class="buttonArr" style="display: flex;">
            <el-button color="#6A5AE9" @click="exportList()"><img style="width: 22px;height: 22px;"
                src="@/assets/export.png" alt="">导出</el-button>

            <el-upload ref="uploaddoc" class="upload-demo" style="margin-left: 12px;" :http-request="httpRequest1"
              :show-file-list="false" :file-list="fileListCopy" action="1" :headers="headers" multiple
              :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" accept=".xlsx">
              <el-button type="primary"><img style="width: 16px;height: 16px;margin-right: 7px;" src="@/assets/import.png"
                  alt="">导入</el-button>
            </el-upload>
          </div>
        </div>
        <div v-show="activeName == 'second'" style="width: 100%;">
          <div class="flex_row_between">
            <div class="buttonArr">
              <el-form :inline="true" class="demo-form-inline" :model="form" label-width="90">
                <el-form-item label="时间周期：">
                  <el-col :span="11">
                    <el-date-picker v-model="formInline2.start_time" type="date" placeholder="开始日期" style="width: 100%"
                      value-format="YYYY-MM-DD" @change="getList2" />
                  </el-col>
                  <el-col :span="2" class="text-center" style="text-align: center;color: #ffffff;">
                    至
                  </el-col>
                  <el-col :span="11">
                    <el-date-picker v-model="formInline2.end_time" type="date" placeholder="结束日期" style="width: 100%"
                      value-format="YYYY-MM-DD" @change="getList2" />
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <div class="buttonArr" style="margin-bottom: 18px;display: flex;">
              <el-button color="#6A5AE9" @click="exportList2()"><img style="width: 22px;height: 22px;"
                  src="@/assets/export.png" alt="">导出</el-button>
              <!-- <el-button type="primary" @click=""><img style="width: 16px;height: 16px;margin-right: 7px;"
                  src="@/assets/import.png" alt="">导入</el-button> -->
              <el-upload ref="uploaddoc" class="upload-demo" style="margin-left: 12px;" :http-request="httpRequest1"
                :show-file-list="false" :file-list="fileListCopy" action="1" :headers="headers" multiple
                :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed"
                accept=".xlsx">
                <el-button type="primary"><img style="width: 16px;height: 16px;margin-right: 7px;"
                    src="@/assets/import.png" alt="">导入</el-button>
              </el-upload>
            </div>
          </div>
          <div class="flex_row_between">
            <div v-for="(e, i) in tjlist" :key="i" class="flex_col_center d-item" :class="{ 'perpos': (i % 2) == 1 }">
              <div class="tjnum">{{ e.num }}</div>
              <div class="tjname">{{ e.name }}</div>
            </div>
          </div>
          <div class="flex_row_between">
            <div class="buttonArr">
              <el-button type="primary" @click="showAdd()">新增链接</el-button>
              <el-button color="#6A5AE9" @click="DownloadFile2">下载导入模板</el-button>
              <el-button type="danger" @click="handleDelete2()">批量删除</el-button>
            </div>
            <div class="buttonArr">
              <!-- <el-button type="primary" @click="grabZhihu()">抓取</el-button> -->
            </div>
          </div>
        </div>
      </div>
      <div style="width: 97.5vw;background-color: #071229;margin: auto;margin-top:20px;padding-bottom: 24px;"
        class="blackbg">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="分发" name="first">
            <el-table :data="tableData" style="background-color: #071229;" :cell-style="cellStyle"
              :header-cell-style="headerStyle" @selection-change="handleSelectionChange3">
              <el-table-column fixed type="selection" width="55" />
              <el-table-column prop="id" label="ID" />
              <el-table-column prop="plat" label="所属平台" />
              <el-table-column prop="account" label="账号" />
              <el-table-column prop="mobile" label="手机号" />
              <el-table-column prop="email" label="邮箱" />
              <el-table-column prop="create_time" label="添加时间">
                <template #default="scope">
                  <div>{{ scope.row.create_time ? formatDate(scope.row.create_time) : '暂无' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="article_count" label="已分配文章数" />
              <el-table-column label="操作" min-width="150">
                <template #default="scope">
                  <div style="display: flex;justify-content: center;cursor: pointer;">
                    <div style="color: #00E2FF;margin-right: 16px;" @click="changFP(1, scope.row)">分配
                    </div>
                    <div style="color: #00E2FF;margin-right: 16px;" @click="changFP(2, scope.row)">
                      修改分配</div>
                    <div style="color: #00E2FF;margin-right: 16px;" @click="showTask(scope.row.id)">
                      配置发布任务</div>
                    <div style="color: #00E2FF;margin-right: 16px;" @click="toEdite(scope.row)">编辑
                    </div>

                    <el-popconfirm width="220" confirm-button-text="确认删除" cancel-button-text="取消"
                      confirm-button-type="danger" icon-color="red" title="确定删除该账号吗?" @confirm="handleDelete(scope.row)">
                      <template #reference>
                        <div style="color: #FF355E;">删除</div>
                      </template>
                    </el-popconfirm>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="page_config.count"
              :page-size="formInline.pagesize" :current-page="formInline.page" :default-current-page="1"
              @current-change="currentChange" style="margin-top: 122px;" class="flex_row_center" />
          </el-tab-pane>
          <el-tab-pane label="数据回收" name="second">
            <el-table :data="tableData2" style="background-color: #071229;width: 100%;" :cell-style="cellStyle"
              :header-cell-style="headerStyle" @selection-change="handleSelectionChange2">
              <el-table-column fixed type="selection" width="55" />
              <el-table-column prop="id" label="ID" width="150" />
              <el-table-column prop="url" label="链接" min-width="200">
                <template #default="scope">
                  <el-popover effect="dark" trigger="hover" placement="top" width="auto">
                    <template #default>
                      <div>{{ scope.row.url }}</div>
                    </template>
                    <template #reference>
                      <div class="overline2" @click="copyClick(scope.row.url)"
                        style="font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 22px;text-align: center;margin: 6px 0;height: 44px;cursor: pointer;">
                        {{ scope.row.url }}
                      </div>
                    </template>
                  </el-popover>

                  <!-- <div class="overline2" @click="copyClick(scope.row.url)"
                style="font-weight: 400;font-size: 14px;color: #FFFFFF;line-height: 22px;text-align: center;margin: 6px 0;height: 44px;cursor: pointer;">
                {{ scope.row.url }}
              </div> -->
                </template>
              </el-table-column>
              <el-table-column prop="plat" label="平台分布" min-width="120" />
              <el-table-column prop="author" label="账号ID" min-width="120" />
              <el-table-column prop="title" label="标题" width="300" />
              <el-table-column prop="plays" label="播放量" min-width="120" />
              <el-table-column prop="views" label="阅读量" min-width="120" />
              <el-table-column prop="comments" label="评论量" min-width="120" />

              <el-table-column prop="likes" label="点赞量" min-width="120" />
              <el-table-column prop="shares" label="转发量" min-width="120" />
              <el-table-column prop="favorites" label="收藏量" min-width="120" />

              <el-table-column prop="create_time" label="添加时间" min-width="120" />
              <el-table-column prop="update_time" label="最近抓取时间" min-width="120" />
              <!-- <el-table-column prop="keyword" label="文章热词(TOP3)" min-width="220" />
              <el-table-column prop="keyword" label="评论热词(TOP3)" min-width="220" /> -->
              <el-table-column fixed="right" label="操作" width="220">
                <template #default="scope">
                  <div class="flex_row_center">
                    <!-- <div style="color: #00E2FF;margin-right: 16px;cursor: pointer;" @click="grabZhihu(scope.row.url)">
                      抓取</div>
                    <div style="color: #00E2FF;margin-right: 16px;cursor: pointer;" @click="detailDialog = true">
                      查看详情</div> -->
                    <el-popconfirm width="220" confirm-button-text="确认删除" cancel-button-text="取消"
                      confirm-button-type="danger" icon-color="red" title="确定删除该链接吗?" @confirm="handleDelete2(scope.row)">
                      <template #reference>
                        <div style="color: #FF355E;cursor: pointer;">删除</div>
                      </template>
                    </el-popconfirm>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="page_config.count"
              :page-size="formInline2.pagesize" :current-page="formInline2.page" :default-current-page="1"
              @current-change="currentChange2" style="margin-top: 122px;" class="flex_row_center" />
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
    <el-dialog v-model="accountDialog" :title="activeName === 'first' ? '新增账号' : '新增链接'" width="400" center
      class="blackdialog">
      <el-form :model="form" label-width="120" style="max-width: 600px;margin: auto;" :rules="rules">
        <div v-if="activeName === 'first'">
          <el-form-item label="所属平台:" required>
            <el-select v-model="addForm.plat" placeholder="请选择所属平台">
              <el-option v-for="(e, i) in platList" :key="i" :label="e.title" :value="e.plat" />
            </el-select>
          </el-form-item>
          <el-form-item label="账号:" required>
            <el-input class="inputClass" v-model="addForm.account" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="手机号:" required>
            <el-input class="inputClass" v-model="addForm.mobile" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="邮箱:" required>
            <el-input class="inputClass" v-model="addForm.email" placeholder="请输入" />
          </el-form-item>
        </div>
        <div v-if="activeName === 'second'">
          <el-form-item label="所属平台:" required>
            <el-select v-model="addtwo.plat" placeholder="请选择所属平台">
              <el-option v-for="(e, i) in plats" :key="i" :label="e" :value="e" />
            </el-select>
          </el-form-item>
          <el-form-item label="文章链接：" required>
            <el-input class="inputClass" v-model="addtwo.url" placeholder="请输入" />
          </el-form-item>
        </div>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="sureAdd()">确认</el-button>
          <el-button @click="accountDialog = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDialog" title="配置发布任务" width="672" center class="blackdialog">
      <el-form :model="form" label-width="90" style="max-width: 600px;margin: auto;" :rules="rules">
        <el-form-item label="时间周期：">
          <el-col :span="11">
            <el-date-picker v-model="taskForm.start_time" type="date" placeholder="开始日期" style="width: 100%"
              value-format="YYYY-MM-DD" />
          </el-col>
          <el-col :span="2" class="text-center" style="text-align: center;">
            至
          </el-col>
          <el-col :span="11">
            <el-date-picker v-model="taskForm.end_time" type="date" placeholder="结束日期" style="width: 100%"
              value-format="YYYY-MM-DD" />
          </el-col>
        </el-form-item>
        <el-form-item label="发布量：">
          <el-input class="inputClass" v-model="taskForm.publish" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="曝光量：">
          <el-input class="inputClass" v-model="taskForm.exposure" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="互动量：">
          <el-input class="inputClass" v-model="taskForm.interaction" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="评论量：">
          <el-input class="inputClass" v-model="taskForm.comment_num" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="评论词：">
          <el-input class="inputClass" v-model="taskForm.comment_words" placeholder="请输入" />
          <div class="warning">注:多次输入请用逗号","隔开</div>
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="setTask()">保存</el-button>
          <el-button @click="addDialog = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="tabDialog" title="分配文章" width="1000" center class="whitetab">
      <el-table :data="gridData" height="413" :header-cell-style="headerStyle1" align="center" ref="multipleTableRef"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column property="title" label="文章标题" align="center" />
        <el-table-column property="type" label="来源" align="center">
          <template #default="scope">
            <div class="d-copy">{{ scope.row.type == 1 ? '词生文' : '文生文' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column property="create_time" label="生成时间" align="center">
          <template #default="scope">
            <div class="d-copy">{{ scope.row.create_time ? scope.row.create_time : '暂无' }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div>已选择{{ multipleSelection ? multipleSelection.length : 0 }}条文章</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="sureFP()">{{ isEdit ? '确认修改' : '分配' }}</el-button>
          <el-button @click="tabDialog = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>


    <!-- 查看详情 -->
    <el-dialog v-model="detailDialog" title="查看详情" width="1000" center>
      <div class="detail">
        <div class="flex_row_left">
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">账号ID：</div>
              <div class="detail-num">2834893</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">阅读量：</div>
              <div class="detail-num">294792</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">转发量：</div>
              <div class="detail-num">2923</div>
            </div>
          </div>
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">标题：</div>
              <div class="detail-num">我是标题我是标题</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">点赞量：</div>
              <div class="detail-num">248937</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">收藏量：</div>
              <div class="detail-num">39423</div>
            </div>
          </div>
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">播放量：</div>
              <div class="detail-num">934937</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">评论量：</div>
              <div class="detail-num">91372</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">发布时间：</div>
              <div class="detail-num">23435</div>
            </div>
          </div>
        </div>

        <div class="detail-title">文章热词</div>
        <div class="flex_row_left">
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">TOP1：</div>
              <div class="detail-num">比亚迪</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">TOP2：</div>
              <div class="detail-num">比亚迪</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">TOP3：</div>
              <div class="detail-num">比亚迪</div>
            </div>
          </div>
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">394</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">356</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">32</div>
            </div>
          </div>
          <div class="flex1">
          </div>
        </div>
        <div class="detail-title">评论热词</div>
        <div class="flex_row_left">
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">TOP1：</div>
              <div class="detail-num">比亚迪</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">TOP2：</div>
              <div class="detail-num">比亚迪</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">TOP3：</div>
              <div class="detail-num">比亚迪</div>
            </div>
          </div>
          <div class="flex1">
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">394</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">356</div>
            </div>
            <div class="flex_row_left">
              <div class="detail-lable">出现次数：</div>
              <div class="detail-num">32</div>
            </div>
          </div>
          <div class="flex1">
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { ElMessage } from 'element-plus'
import { https, baseURL } from '@/request.js';
import useClipboard from 'vue-clipboard3';
const addDialog = ref(false)
const accountDialog = ref(false)
const detailDialog = ref(false)
const tjlist = ref([
  { name: '播放量', num: 0 },
  { name: '阅读量', num: 0 },
  { name: '评论量', num: 0 },
  { name: '点赞量', num: 0 },
  { name: '转发量', num: 0 },
  { name: '收藏量', num: 0 },
])
function grabZhihu(url) {
  https.post('/index/Data/zhihuFetchTest', { url: url }).then(res => {
    if (res.code == 0) {
      ElMessage.success('抓取成功!')
      getList2()
    }
  })
}
//一键复制
const { toClipboard } = useClipboard();
/*
   * 点击的方法
   * @item 需要复制的内容
   */
const copyClick = async item => {
  try {
    await toClipboard(item);
    // message.success('复制成功');
    ElMessage({
      message: '链接已复制',
      type: 'success',
    })
  } catch (e) {
    console.error(e);
  }
};
function currentChange(val) {
  formInline.value.page = val;
  getList()
}
function currentChange2(val) {
  formInline2.value.page = val;
  getList2()
}
// 时间戳转换为时间格式
function formatDate(val) {
  if (!val || val == 0) return '暂无'
  let date = new Date(parseInt(val * 1000));
  let Y = date.getFullYear() + '-';
  let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
  let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
  let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
  let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
const formData = ref(new FormData())
function httpRequest1(e) {
  console.log(e)
  if (formData.value.has('file')) {
    formData.value.delete('file')
  }
  formData.value.append('file', e.file)
  https.post('/index/Upload/uploadFile', formData.value).then(res => {
    if (res.code == 0) {
      if (activeName.value == 'first') {
        https.post('/index/Data/importDataHand', {
          file_path: res.file_path,
          type: 'data',
          project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
        }).then(ress => {
          if (ress.code == 0) {
            ElMessage.success({
              message: '上传成功!',
            })
            getList()
          }
        })
      } else {
        https.post('/index/Data/importDataRecovery', {
          file_path: res.file_path,
          type: 'data',
          project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
        }).then(ress => {
          if (ress.code == 0) {
            ElMessage.success({
              message: '上传成功!',
            })
            getList()
          }
        })
      }

      uploaddoc.value.clearFiles()
      formData.value.delete('file')

    }
  })
}
const fileListCopy = ref([])
const uploaddoc = ref()
function handleRemove() {
  formData.value.delete('file')
  fileListCopy.value = []
}
function handleExceed(files, fileList) {
  ElMessage({
    message: `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`,
    type: 'warning',
  })
  console.log(files)
}
function beforeRemove(file) {
  ElMessageBox.confirm(
    `确定移除 ${file.name}？`,
    'Warning',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      ElMessage({
        type: 'success',
        message: 'Delete completed',
      })
      return true
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Delete canceled',
      })
      return false
    })

}
const DownloadFile = () => {
  let url = baseURL + '/template/智能分发导入模板.xlsx';
  window.open(url, '_blank')
}
const DownloadFile2 = () => {
  let url = baseURL + '/template/数据回收导入模板.xlsx';
  window.open(url, '_blank')
}
function exportList() {
  let currentProgram = JSON.parse(localStorage.getItem('currentProgram'));
  let username = localStorage.getItem('username');
  let url = baseURL + '/index/Data/exportDataHand?project_id=' + currentProgram.id + '&username=' + username;
  window.open(url, '_blank')
}
function exportList2() {
  let currentProgram = JSON.parse(localStorage.getItem('currentProgram'));
  let username = localStorage.getItem('username');
  let url = baseURL + '/index/Data/exportDataRecovery?project_id=' + currentProgram.id + '&username=' + username;
  window.open(url, '_blank')
}
const taskForm = ref({
  id: '',
  publish: '',
  exposure: '',
  interaction: '',
  comment_num: '',
  comment_words: '',
  start_time: '',
  end_time: '',
})
function showTask(id) {
  taskForm.value.id = id;
  addDialog.value = true
}
function setTask() {
  https.post('/index/Data/configTask', taskForm.value).then(res => {
    if (res.code == 0) {
      ElMessage.success('配置成功!')
      getList()
      addDialog.value = false
    }
  })
}
const platList = ref(['百度', '百度资讯', '百度视频', '知乎', '小红书', '微信搜一搜', '微博', '今日头条', '抖音'])
const rules = reactive({
  date1: [
    {
      type: 'date',
      required: true,
      message: 'Please pick a date',
      trigger: 'change',
    },
  ],
  name: [
    { required: true, message: '请输入项目名称', trigger: 'blur' },
  ],
  leader: [
    {
      required: true,
      message: '请输入项目负责人',
      trigger: 'blur',
    },
  ],
  start: [
    {
      required: true,
      message: '请输入初始关键字',
      trigger: 'blur',
    },
  ],
  target: [
    {
      required: true,
      message: '请输入项目目标',
      trigger: 'blur',
    },
  ]

})
const isAdd = ref(true)
const showAdd = () => {
  addForm.value = {
    account: '',
    plat: '',
    mobile: '',
    email: '',
    project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
  }
  addtwo.value = {
    plat: '',
    url: '',
    project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
  }
  isAdd.value = true;
  accountDialog.value = true
}
const tabDialog = ref(false)
const gridData = ref([])
const headerStyle1 = () => {
  return {
    color: "#333333",
    textAlign: "center",
    background: "#EAEAEB",
    fontWeight: 700,
    height: '45px',
    lineHeight: '45px',
    border: 'none'
  }
}
const FPparam = ref({
  id: 0,
  article_ids: ''
})
const multipleTableRef = ref()
const multipleSelection = ref([])
const toggleSelection = (rows) => {
  if (rows) {
    rows.forEach((row) => {
      multipleTableRef.value.toggleRowSelection(row, row.selected)
    })
  } else {
    multipleTableRef.value.clearSelection()
  }
}
const isEdit = ref(false)
const changFP = (i, e) => {
  FPparam.value.id = e.id;
  tabDialog.value = true;
  if (i == 2) {
    isEdit.value = true;
    let ARR = []
    let article_ids = e.article_ids.split(',')
    console.error(article_ids)
    console.error(gridData.value)
    if (article_ids && article_ids.length > 0) {
      article_ids.map(e => {
        let idx = gridData.value.findIndex(item => item.id == e)
        ARR.push(gridData.value[idx])
      })
    }
    setTimeout(() => {
      toggleSelection()
      toggleSelection(ARR)
    }, 200);
  } else {
    isEdit.value = false
    setTimeout(() => {
      toggleSelection()
    }, 200);
  }

}
const handleSelectionChange = (val) => {
  multipleSelection.value = val
}
const multipleSelection2 = ref([])
const handleSelectionChange2 = (val) => {
  multipleSelection2.value = val
}

const multipleSelection3 = ref([])
const handleSelectionChange3 = (val) => {
  multipleSelection3.value = val
}

function sureFP() {
  if (multipleSelection.value && multipleSelection.value.length > 0) {
    let idarr = multipleSelection.value.map(e => {
      return e.id
    })
    FPparam.value.article_ids = idarr.join(',');
    https.post('/index/Data/publishTask', FPparam.value).then(res => {
      if (res.code == 0) {
        ElMessage.success('分配成功!')
        getList()
        tabDialog.value = false
      }
    })
  } else {
    ElMessage.error('请选择文章!')
  }
}
const activeName = ref('first')

const handleClick = (tab, event) => {
  console.log(tab.paneName, event)
  activeName.value = tab.paneName
  if (activeName.value == 'first') {
    formInline.value.page = 1;
    getList()
  } else {
    formInline2.value.page = 1;
    getList2()
  }
}
// const titleDialog = ref('')
const tableData = ref([

])
const tableData2 = ref([

])
//删除分发
const handleDelete = (row) => {
  https.post('/index/Data/delDataHand', { id: row.id }).then(res => {
    if (res.code == 0) {
      ElMessage.success({
        message: '删除成功!',
      })
      getList()
    }
  })
}
//批量删除数据回收
const handleDelete2 = (row) => {
  let ids = ''
  if (!row) {
    if (multipleSelection2.value && multipleSelection2.value.length > 0) {
      let idarr = multipleSelection2.value.map(e => {
        return e.id
      })
      ids = idarr.join(',');
    }else{
      ElMessage.error({
        message: '请先选择要删除的链接!',
      })
      return
    }
  }else{
    ids = row.id
  }
  https.post('/index/Data/delDataRecovery', { ids: ids }).then(res => {
    if (res.code == 0) {
      ElMessage.success({
        message: '删除成功!',
      })
      getList2()
    }
  })
}

//批量删除分发
const handleDelete3 = (row) => {
  let ids = ''
  if (!row) {
    if (multipleSelection3.value && multipleSelection3.value.length > 0) {
      let idarr = multipleSelection3.value.map(e => {
        return e.id
      })
      ids = idarr.join(',');
    }else{
      ElMessage.error({
        message: '请先选择要删除的分发!',
      })
      return
    }
  }else{
    ids = row.id
  }
  https.post('/index/Data/delDataHand', { id: ids }).then(res => {
    if (res.code == 0) {
      ElMessage.success({
        message: '删除成功!',
      })
      getList()
    }
  })
}

const cellStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "#071229",
    fontWeight: 700,
    height: '46px',
    lineHeight: '46px',
    padding: '0px',
    border: 'none',
    borderBottom: '1px solid rgba(15,178,245,0.11)',
  };

}
const headerStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "rgba(106, 90, 233, 0.12)",
    fontWeight: 700,
    height: '45px',
    lineHeight: '45px',
    border: 'none'
  }
}
const formInline = ref({
  page: 1,
  pagesize: 10,
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
})
const page_config = ref({
  count: 0,
  current: "1",
  last_page: 0,
  next: 0,
  pre: ""
})
function getList() {
  https.post('/index/Data/index', formInline.value).then(res => {
    if (res.code == 0) {
      tableData.value = res.data.list
      page_config.value = res.data.page_config
    }
  })
}
getList()

const formInline2 = ref({
  page: 1,
  pagesize: 10,
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
  start_time: '',
  end_time: ''
})
const statistics = ref({
  comments_total: 0,
  favorites_total: 0,
  likes_total: 0,
  plays_total: 0,
  shares_total: 0,
  views_total: 0,
})
function getList2() {
  https.post('/index/Data/dataRecoveryList', formInline2.value).then(res => {
    if (res.code == 0) {
      tableData2.value = res.data.list;
      statistics.value = res.data.statistics;
      page_config.value = res.data.page_config
      tjlist.value[0].num = statistics.value.plays_total;
      tjlist.value[1].num = statistics.value.views_total;
      tjlist.value[2].num = statistics.value.comments_total;
      tjlist.value[3].num = statistics.value.likes_total;
      tjlist.value[4].num = statistics.value.shares_total;
      tjlist.value[5].num = statistics.value.favorites_total;
    }
  })
}

const artParam = ref({
  page: 1,
  pagesize: 10000,
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
})
function getArtList() {
  https.post('/index/Data/askArticleList', artParam.value).then(res => {
    if (res.code == 0) {
      gridData.value = res.data.list
    }
  })
}
getArtList()
const addForm = ref({
  account: '',
  plat: '',
  mobile: '',
  email: '',
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
})
const addtwo = ref({
  plat: '',
  url: '',
  project_id: JSON.parse(localStorage.getItem('currentProgram')).id,
})
const plats = ref([])
function gettwoPlat() {
  https.post('/index/Data/recoveryPlatList').then(res => {
    if (res.code == 0) {
      plats.value = res.data
    }
  })
}
gettwoPlat()
const sureAdd = () => {

  if (isAdd.value) {
    if (activeName.value === 'first') {
      if (addForm.value.account == '' || addForm.value.plat == '' || addForm.value.mobile == '' || addForm.value.email == '') {
        ElMessage.error('请输入完整信息!')
        return
      }
      https.post('/index/Data/addDataHand', addForm.value).then(res => {
        if (res.code == 0) {
          ElMessage.success('添加成功!')
          getList()
          accountDialog.value = false
        }
      })
    } else {
      if (addtwo.value.plat == '' || addtwo.value.url == '') {
        ElMessage.error('请输入完整信息!')
        return
      }
      https.post('/index/Data/addRecoveryLink', addtwo.value).then(res => {
        if (res.code == 0) {
          ElMessage.success('添加成功!')
          getList2()
          accountDialog.value = false
        }
      })
    }

  } else {
    https.post('/index/Data/editDataHand', addForm.value).then(res => {
      if (res.code == 0) {
        ElMessage.success('编辑成功!')
        getList()
        accountDialog.value = false
      }
    })
  }

}
const toEdite = (e) => {
  let obj = JSON.parse(JSON.stringify(e))
  addForm.value.account = obj.account;
  addForm.value.plat = obj.plat;
  addForm.value.mobile = obj.mobile;
  addForm.value.email = obj.email;
  addForm.value.id = obj.id;
  isAdd.value = false;
  accountDialog.value = true;
}

function getPlat() {
  https.post('/index/index/getPlat', {}).then(res => {
    if (res.code == 0) {
      platList.value = res.data
    }
  })
}
getPlat()
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.detail {
  padding: 5px 90px;
}

.detail-lable {
  width: 70px;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  line-height: 20px;
  text-align: right;
  font-style: normal;
  margin-right: 24px;
  margin-bottom: 30px;
}

.detail-num {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
}

.detail-title {
  height: 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: rgba(34, 34, 34, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-bottom: 26px;
}

.warning {
  color: #FF355E;
}

.head {
  display: flex;
  justify-content: space-between;
}

.breadcrumbs {
  color: white;
}

.searchMain {
  height: 40px;
  line-height: 40px
}

.distribute-page {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/bgi.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

::v-deep .el-tabs__item {
  color: rgba(255, 255, 255, 0.69);
  margin-left: 48px;
}

::v-deep .el-tabs__item.is-active {
  color: #FFFFFF;
}

::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.16);
}

::v-deep .el-tabs__active-bar {
  height: 5px;
  background-color: #FFFFFF;
}

::v-deep.el-table tr {
  background-color: #FFF !important;
}

.blackbg ::v-deep .el-table tr {
  background-color: #071229 !important;
}

.d-item {
  width: 175px;
  height: 88px;
  background: rgba(7, 119, 252, 0.3);
  border-radius: 4px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.tjnum {
  height: 25px;
  font-family: Roboto, Roboto;
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  font-style: italic;
}

.tjname {
  height: 21px;
  font-family: PingFangSC, PingFang SC;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  font-style: normal;
  margin-top: 8px;
}

.perpos {
  background: rgba(106, 90, 233, 0.3);
}

::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--right,
::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--left {
  background: #131A41 !important;
}
</style>