<template>
  <div class="secondPage">
    <!-- <navTab></navTab> -->
    <div style="margin-top: 92px;display: flex;width: 100vw;">
      <div class="newOrJump" @click="addNewProject()"
        style="background: rgba(106,90,233,0.3);border-radius: 6px;border: 1px solid #7260FF;cursor: pointer;">
        <div>
          <img class="iconText" src="@/assets/addNewProject.png" alt="">
          <div class="textTitle">新建项目</div>
        </div>
      </div>
      <div class="newOrJump" @click="skip()"
        style="background: rgba(7,119,252,0.3);border-radius: 6px;border: 1px solid #2E8EFF;">
        <div>
          <img class="iconText" src="@/assets/skip.png" alt="">
          <div class="textTitle">跳过</div>
        </div>
      </div>
    </div>
    <div style="margin-top: 28px;width: 100vw;">
      <div style="width: 97.5vw;height: 69.5vh;background-color: #071229;margin: auto;">
        <el-table :data="tableData" style="background-color: #071229;" :cell-style="cellStyle"
          :header-cell-style="headerStyle">
          <el-table-column prop="id" label="ID" />
          <el-table-column prop="title" label="项目名称" max-width="180">
            <template #default="scope">
              <div style="color: #5AA6FF;cursor: pointer;text-decoration: underline;" @click="saveCurrent(scope.row)">
                {{ scope.row.title ? scope.row.title : "" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="username" label="负责人" max-width="180">

          </el-table-column>
          <el-table-column prop="keywords" label="初始关键词" width="180" />
          <el-table-column prop="extend" label="已拓展关键词" width="200">
            <template #default="scope">
              <div class="flex_row_center" style="cursor: pointer;" @click="toDetal(scope.row)">{{ scope.row.extend }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="现状" min-width="460">
            <template #default="scope">
              <div class="flex_row_center">
                <div class="flex_row_center" v-for="(e, i) in scope.row.plat_list">
                  {{ e.plat ? getName(e.plat) : '' }}
                  <div class="line0" :style="{ background: e.color }"></div>
                </div>
                <!-- 小红书
                <div class="line0"></div>
                抖音
                <div class="line0 line1"></div>
                百度
                <div class="line0 line2"></div>
                微博
                <div class="line0 line3"></div> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column label="目标" width="460">
            <template #default="scope">
              <div style="margin: 6px 0;">
                <div v-for="(e, i) in scope.row.plat_list">
                  {{ e.plat ? getName(e.plat) : '' }}前{{ e.top_count ? e.top_count : '0' }}条非负率{{ e.top_rate ? e.top_rate : '0' }}%;
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="status" label="完成情况" max-width="180" /> -->
          <el-table-column prop="status" label="项目进度" align="center">
            <template #default="scope">
              <div class="tag" :class="{ 'overtag': scope.row.status != 1 }">
                {{ scope.row.status === 1 ? '执行中' : '完成' }}
              </div>

            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="120">
            <template #default="scope">
              <div style="display: flex;justify-content: center;">
                <div style="color: #00DFFF;cursor: pointer;" @click="handleEdit(scope.row)">编辑</div>
                <div style="width: 1px;height: 15px;background: #D8D8D8;opacity: 0.23;margin: 5px 10px;"></div>
                <!-- <div style="color: #FF355E;cursor: pointer;" @click="handleDelete(scope.$index, scope.row)">删除</div> -->
                <el-popconfirm width="220" confirm-button-text="确认删除" cancel-button-text="取消"
                  confirm-button-type="danger" icon-color="red" title="确定删除该项目吗?"
                  @confirm="handleDelete(scope.$index, scope.row)">
                  <template #reference>
                    <!-- <el-button>删除</el-button> -->
                    <div style="color: #FF355E;cursor: pointer;">删除</div>
                  </template>
                </el-popconfirm>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="page_config.count" :page-size="pageInfo.pagesize"
          :current-page="pageInfo.page" :default-current-page="1" @current-change="currentChange"
          style="margin-top: 122px;" class="flex_row_center" />
      </div>
    </div>
    <el-dialog v-model="centerDialogVisible" :title="isEdit ? '编辑项目' : '新建项目'" width="742" center class="blackdialog">
      <el-form ref="ruleFormRef" :model="addform" label-width="auto" style="max-width: 600px;margin: auto;"
        :rules="rules">
        <el-form-item label="项目名称:" prop="title">
          <el-input class="inputClass" v-model="addform.title" placeholder="请输入项目名称" />
        </el-form-item>
        <el-form-item label="项目负责人:" prop="uid">
          <el-select v-model="addform.uid" placeholder="请选择项目负责人">
            <el-option v-for="(item, i) in AdminList" :key="i" :label="item.username" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="初始关键词:" prop="keywords">
          <el-input class="inputClass" v-model="addform.keywords" placeholder="请输入初始关键词" />
          <div class="warning">注:多次输入请用逗号","隔开</div>
        </el-form-item>
        <el-form-item label="拓展关键词:">
          <el-input class="inputClass" v-model="addform.extend" placeholder="请输入拓展关键词" />
          <div class="warning">注:多次输入请用逗号","隔开</div>
        </el-form-item>
        <el-form-item label="搜索条数:">
          <el-input class="inputClass" v-model="addform.search_count" placeholder="请输入搜索条数" type="number" />
        </el-form-item>
        <el-form-item label="目标:">
          <!-- <el-input class="inputClass" v-model="addform.target_count" placeholder="请输入目标条数" type="number" /> -->
          <div class="flex_row_left" style="margin-bottom: 10px;" v-for="(e, i) in addform.plats">
            <el-select class="flex1" style="margin-right: 10px;" v-model="e.plat" placeholder="选择平台">
              <el-option v-for="(e, i) in palts" :key="i" :label="e.title" :value="e.plat" />
            </el-select>
            <el-input class="flex1" style="margin-right: 10px;" v-model="e.top_count" placeholder="设置条数"
              type="number" />
            <el-input class="flex1" style="margin-right: 10px;" v-model="e.top_rate" placeholder="输入非负率"
              type="number" />
            <div class="flex_row_left homeadd" @click="addtargt" v-if="i == 0">
              <img src="@/assets/homeadd.png" alt="">增加
            </div>
            <div class="flex_row_left homeadd homedel" @click="deltargt(i)" v-else>
              <img src="@/assets/homedel.png" alt="">删除
            </div>
          </div>
        </el-form-item>
        <el-form-item label="项目状态:">
          <el-radio-group v-model="addform.status">
            <el-radio :value="1">运行</el-radio>
            <el-radio :value="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save(ruleFormRef)">保存</el-button>
          <el-button @click="centerDialogVisible = false; resetForm()">取消</el-button>
        </el-form-item>
      </el-form>

      <!-- <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="save()">保存</el-button>
          <el-button @click="centerDialogVisible = false">
            取消
          </el-button>
        </div>
      </template> -->
    </el-dialog>
  </div>
</template>

<script setup>

import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'
import { https } from '@/request.js';
const userRouter = useRouter()
const userRoute = useRoute()
console.log(userRouter, userRoute);
const skip = () => {
  userRouter.push({
    path: '/search'
  })
}
let addform = ref({
  title: '',
  uid: '',
  keywords: '',
  extend: '',
  search_count: '',
  target_count: '',
  plats: [{
    plat: '',
    top_count: '',
    top_rate: ""
  }],
  status: 1
})
const palts = ref([])
function getPlat() {
  https.post('/index/index/getPlat', {}).then(res => {
    if (res.code == 0) {
        palts.value = res.data
    }
  })
}
getPlat()
function getName(e){
    let i = palts.value.findIndex(item => item.plat == e)
    return palts.value[i].title
}
const rules = reactive({
  title: [
    { required: true, message: '请输入项目名称', trigger: 'blur' },
  ],
  uid: [
    {
      required: true,
      message: '请选择项目负责人',
      trigger: 'blur',
    },
  ],
  keywords: [
    {
      required: true,
      message: '请输入初始关键字',
      trigger: 'blur',
    },
  ],
  target_count: [
    {
      required: true,
      message: '请输入项目目标',
      trigger: 'blur',
    },
  ]

})
function saveCurrent(e) {
  localStorage.setItem('currentProgram', JSON.stringify(e))
  // userRouter.push({
  //   path: '/analysis/detail',
  //   query: {
  //     keywords: e.keywords
  //   }
  // })
  userRouter.push('/overview')
}
function toDetal(e) {
  userRouter.push({
    path: '/analysis/detail',
    query: {
      keywords: e.extend
    }
  })
}
let centerDialogVisible = ref(false)
const cellStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "#071229",
    fontWeight: 700,
    height: '29px',
    lineHeight: '29px',
    padding: '0px',
    border: 'none',
    borderBottom: '1px solid rgba(15,178,245,0.11)',
  };

}
const addtargt = () => {
  addform.value.plats.push({
    plat: '',
    top_count: '',
    top_rate: ""
  })
}
const deltargt = (i) => {
  addform.value.plats.splice(i, 1)
}
const addNewProject = () => {
  isEdit.value = false
  delete addform.value.id
  centerDialogVisible.value = true
  setTimeout(() => {
    resetForm()
  }, 200);
}
const headerStyle = () => {
  return {
    color: "#fff",
    textAlign: "center",
    background: "rgba(106, 90, 233, 0.12)",
    fontWeight: 700,
    height: '45px',
    lineHeight: '45px',
    border: 'none'
  }
}
const isEdit = ref(false)
const handleEdit = (row) => {
  isEdit.value = true
  let obj = JSON.parse(JSON.stringify(row))
  addform.value.title = obj.title;
  addform.value.uid = obj.uid;
  addform.value.keywords = obj.keywords;
  addform.value.extend = obj.extend;
  addform.value.search_count = obj.search_count;
  addform.value.target_count = obj.target_count;
  addform.value.plats = obj.plat_list;
  addform.value.status = obj.status;
  addform.value.id = obj.id;
  centerDialogVisible.value = true
}
const handleDelete = (index, row) => {
  console.log(index, row)
  https.post('/index/Project/delProject', { id: row.id }).then(res => {
    if (res.code == 0) {
      ElMessage.success({
        message: '删除项目成功!',
      })
      getList()
    }
  })
}

const tableData = ref([])
const AdminList = ref([])
function getAdmins() {
  https.post('/index/User/getAdminList', {}).then(res => {
    if (res.code == 0) {
      AdminList.value = res.data
    }
  })
}
getAdmins()
const pageInfo = reactive({
  page: 1,
  pagesize: 10
})
const page_config = ref({
  count: 0,
  current: "1",
  last_page: 0,
  next: 0,
  pre: ""
})
function getList() {
  https.post('/index/Project/index', {
    status: '1,2',
    page: pageInfo.page,
    pagesize: pageInfo.pagesize
  }).then(res => {
    if (res.code == 0) {
      tableData.value = res.data.list
      console.log(111)
      console.log(tableData.value)
      page_config.value = res.data.page_config
      //空或未定义
      if (!localStorage.getItem('currentProgram') ||  localStorage.getItem('currentProgram') == 'undefined') {
        let currentProgram = {'id':'', 'username':''}
        console.log(tableData.value.length)
        if(tableData.value.length > 0){
          currentProgram = tableData.value[0]
        }
        console.log(222)
        console.log(currentProgram)
        localStorage.setItem('currentProgram', JSON.stringify(currentProgram))
      }
    }else {
      let currentProgram = {'id':'', 'username':''}
      localStorage.setItem('currentProgram', JSON.stringify(currentProgram))
    }
  })
}
function currentChange(val) {
  pageInfo.page = val;
  getList()
}
getList()
const ruleFormRef = ref()
const save = () => {
  if (!ruleFormRef.value) return
  ruleFormRef.value.validate((valid) => {  // 注意：此时使用的是 ruleFormRef.value，而仅写 ruleFormRef 是拿不到值且会报错的;
    if (valid) {   // 注意：只有当所有的规则都满足后，此时的 valid 的值才为 true，才能执行下面的值;
      if (isEdit.value == true) {
        https.post('/index/Project/editProject', addform.value).then(res => {
          if (res.code == 0) {
            ElMessage.success({
              message: '编辑项目成功!',
            })
            centerDialogVisible.value = false;
            getList()
          }
        })
      } else {
        https.post('/index/Project/addProject', addform.value).then(res => {
          if (res.code == 0) {
            ElMessage.success({
              message: '新建项目成功!',
            })
            centerDialogVisible.value = false;
            getList()
          }
        })
      }

    } else {
      ElMessage.error('请输入必填项!')
      return false
    }
  })
}
const resetForm = () => {
  addform.value = {
    title: '',
    uid: '',
    keywords: '',
    extend: '',
    search_count: '',
    target_count: '',
    plats: [{
      plat: '',
      top_count: '',
      top_rate: ""
    }],
    status: 1
  }
  if (!ruleFormRef.value) return
  ruleFormRef.value.resetFields()
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.warning {
  color: red;
}

.line0 {
  width: 28px;
  height: 3px;
  background: #FF1515;
  border-radius: 3px;
  margin: 0 10px 0 4px
}

.line1 {
  background: #EAB31A;
}

.line2 {
  background: #1A60DA;
}

.line3 {
  background: #1ADA33;
  margin: 0 0px 0 4px
}

.tag {
  width: 46px;
  height: 22px;
  background: rgba(26, 185, 218, 0.16);
  border-radius: 4px;
  border: 1px solid #1AB9DA;
  font-family: AlibabaPuHuiTi_2_65_Medium;
  font-size: 12px;
  color: #1AB9DA;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  margin: 16px auto;
}

.overtag {
  border: 1px solid #E7C13F;
  color: #E7C13F;
}

.homeadd {
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0777FC;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
}

.homeadd img {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}

.homedel {
  color: #FF4453;
}

::v-deep .el-table__inner-wrapper::before {
  content: none;
}

::v-deep .el-form-item__content {
  max-width: 400px;
  flex: auto;
}

::v-deep .el-form-item {
  justify-content: center;
}

.inputClass {
  width: 400px;
}

.el-pagination .el-pager li:not(.active) button {
  background-color: #DCDCDC;
  opacity: 0.26;
}

.textTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: normal;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: right;
  font-style: normal;
}

.iconText {
  width: 46px;
  height: 46px;
}

.newOrJump {
  width: 48vw;
  height: 17.2vh;
  margin-left: 24px;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 17.2vh */
}

.secondPage {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/bgi.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--right,
::v-deep .el-table__header-wrapper tr th.el-table-fixed-column--left {
  background: #131A41 !important;
}
</style>