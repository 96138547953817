
import { createRouter, createWebHashHistory } from 'vue-router'
import firstPage from '@/views/firstPage/firstPage'
import registerPage from '@/views/firstPage/registerPage'
import secondPage from '@/views/secondPage/secondPage'
import overview from '@/views/secondPage/overview'
import keywordoverview from '@/views/analysis/keyword-overview'
import more from '@/views/analysis/more'
import skipSearch from '../views/skipSearch/skipSearch.vue'
import distriutePage from '../views/distribute/distributePage.vue'

const routes = [
  { path: '/', component: firstPage },
  { path: '/register', component: registerPage },
  { path: '/second', component: secondPage },
  { path: '/overview', component: overview },
  { path: '/keywordoverview', component: keywordoverview },
  { path: '/more', component: more },
  { path: '/search', component: skipSearch },
  { path: '/distribute', component:distriutePage },
  {
    path: '/behavior',
    children: [
      {
        path: '/behavior/data',
        name: 'behaviorData',
        component: () => import('@/views/behavior/behaviorData.vue')    // component: import('../views/reg.vue')
      },
      {
        path: '/behavior/set',
        name: 'behaviorSet',
        component: () => import('@/views/behavior/behaviorSet.vue')
      }
    ]
  },
  {
    path: '/role',
    children: [
      {
        path: '/role/group',
        name: 'roleGroup',
        component: () => import('@/views/role/roleGroup.vue')    // component: import('../views/reg.vue')
      },
      {
        path: '/role/admin',
        name: 'roleAdmin',
        component: () => import('@/views/role/roleAdmin.vue')
      }
    ]
  },
  {
    path: '/analysis',
    children: [
      {
        path: '/analysis/detail',
        name: 'analysisdetail',
        component: () => import('@/views/analysis/analysisFirst.vue')    // component: import('../views/reg.vue')
      },
      {
        path: '/analysis/history',
        name: 'history',
        component: () => import('@/views/analysis/analysisHistory.vue')
      }
    ]
  },
  {
    path: '/ai',
    children: [
      {
        path: '/ai/aiWord',
        name: 'aiWord',
        component: () => import('@/views/ai/aiWord.vue')    // component: import('../views/reg.vue')
      },
      {
        path: '/ai/aiWord/detail/:Id',
        name: 'aiWordDetail',
        component: () => import('@/views/ai/aiWordDetail.vue')
      },
      {
        path: '/ai/aiArticle',
        name: 'aiArticle',
        component: () => import('@/views/ai/aiArticle.vue')
      }
    ]
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})
//  在全局前置守卫中检查路由变化
// router.beforeEach((to, from, next) => {
//   // 如果满足特定条件（例如用户登出），则导航到首页
//   if (/* 条件判断 */) {
//     router.push('/');
//   } else {
//     next();
//   }
// });
export default router